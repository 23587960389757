import React, {useEffect} from 'react';
import './resume.scss';

function Resume() {


  useEffect(() => {
    document.body.style.backgroundColor = "#f3f3f3";
  }, []);


  return (
    <div className='sheet'>
        lol
    </div>
  );


}

export default Resume;
