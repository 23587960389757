import React from 'react';
import styles from '../trophyApp.module.scss';

const AvatarComp = ({profileUrl}) => {
  return (
    <div className={styles['avatar']} style={{backgroundImage: `url(${profileUrl})`}}></div>
  );
};

export default AvatarComp;
