import React from 'react';
import styles from '../trophyApp.module.scss';

const ConsoleTagComp = ({ plataform }) => {
  return (
    <div className={styles['tag']} style={{flex: '1'}}>
        <div className={styles['console-tag']}>     
            {plataform} 
        </div>
    </div>
  );
};

export default ConsoleTagComp;
