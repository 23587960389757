import React from 'react';
import styles from '../trophyApp.module.scss';

const TrophyImgComp = ({ src, alt }) => {
  return (
    <div className={styles['trophy-img']} style={{flex: '1'}}>
        <img src={src} alt={alt}/>
    </div>
  );
};

export default TrophyImgComp;
