import React from 'react';

const GameNameComp = ( {gameName}) => {
  return (
    <div style={{flex: '1'}}>
        {gameName}
    </div>
  );
};

export default GameNameComp;
