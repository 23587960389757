import React from 'react';
import styles from '../trophyApp.module.scss';

const HoverInfoComp = ({ text, position }) => {
    if(position === "center") return <div className={styles['hover-info']}>{text}</div>;
    if(position === "left") return <div className={styles['hover-info-left']}>{text}</div>;
    if(position === "right") return <div className={styles['hover-info-right']}>{text}</div>;
};

export default HoverInfoComp;
