import React, { useEffect, useState, useRef } from 'react';
import { 
  FaCode, 
  FaTerminal, 
  FaGlobe,
  FaWrench,
  FaFileAlt,
  FaGamepad,
  FaHouseUser,
  FaBriefcase,
  FaMapMarkedAlt,
  FaEnvelope,
  FaWhatsapp,
  FaGraduationCap,
  FaHeart,
  FaRobot,
  FaBook,
  FaFileDownload
} from "react-icons/fa";
import './home.scss';

import { useReactToPrint } from 'react-to-print';


function ChangeLanguage({ event, idioma, setIdioma }) {

  let [ openMenu, setOpenMenu ] = useState(false);

  function abrirMudarIdioma() {
    console.log('abriu')
    setOpenMenu(true);
  }

  function mudarIdioma(string) {
    if (openMenu) {
      if (string == 'eng') { setIdioma('eng') }
      else if (string == 'ptbr') { setIdioma('ptbr') }
    }
    console.log(idioma);
    setOpenMenu(false);
  }

  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current.contains(event.target)) {
        console.log('clicou fora');
        setOpenMenu(false);
      } else {
        console.log('clicou dentro');
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);



  return (
    <>
      <div className='change-language' ref={ref}>
        <div className='ico'>
          <FaGlobe size={16} />
        </div>
        <div className='selected' onClick={abrirMudarIdioma}>
        { idioma === 'eng' ? <> English </> : null } 
        { idioma === 'ptbr' ? <> Português </> : null }
        </div>

        { openMenu && 
        <>
          <div className='selection'> 
            { idioma === 'eng' ? <>
            <div className='item' onClick={ () => mudarIdioma('eng')}> English </div> 
            <div className='item' onClick={ () => mudarIdioma('ptbr')}> Portuguese </div>
            </> : null }
            { idioma === 'ptbr' ? <>
            <div className='item' onClick={ () => mudarIdioma('eng')}> Inglês </div> 
            <div className='item' onClick={ () => mudarIdioma('ptbr')}> Português </div>
            </> : null }
          </div>
        </>} 
      </div>
    </>
  );
}

function Lista({ dados }) {
  return (
    <ul>
      {dados.map((item) => (
        <li className="item" key={item.nome}>
          <div className="item-name">{item.nome}</div>
          <div className="item-level">
            <div className="level-bg">
              <div className="level-inside" style={{ width: item.level + '%' }}> 
                {item.level + '%'}
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}


function Home() {

  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let [ language, setLanguage ] = useState('eng'); 

  const menuItems = {
    aboutMe: {
      eng: 'about me',
      ptbr: 'sobre mim'
    },
    interests: {
      eng: 'interests',
      ptbr: 'interesses'
    },
    details: {
      eng: 'details',
      ptbr: 'detalhes'
    },
    education: {
      eng: 'education',
      ptbr:'formação'
    },
    //
    coding: {
      eng: 'Coding',
      ptbr:'Linguagens'
    },
    engines: {
      eng: 'Engines',
      ptbr:'Engines'
    },
    frameworks: {
      eng: 'Frameworks',
      ptbr:'Frameworks'
    },
    tools: {
      eng: 'Tools',
      ptbr:'Ferramentas'
    },
    libs: {
      eng: 'Libs',
      ptbr:'Bibliotecas'
    },
    languages: {
      eng: 'Languages',
      ptbr:'Idiomas'
    },
  }

  const ArrayJhey = {
    coding: [
      { nome: 'HTML', level: 100 },
      { nome: 'CSS', level: 100 },
      { nome: 'SASS / SCSS', level: 100 },
      { nome: 'JavaScript', level: 95 },
      { nome: 'TypeScript', level: 85 },
      { nome: 'PHP', level: 75 },
      { nome: 'C#', level: 75 },
      { nome: 'Python', level: 70 },
    ],
    engines: [
      { nome: 'Game Maker Studio 2', level: 95 },
      { nome: 'Unity', level: 85 },
      { nome: 'Godot', level: 75 },
      { nome: 'Unreal Engine', level: 65 },
    ],
    frameworks: [
      { nome: "React", level: 90 },
      { nome: 'React Native', level: 80 },
      { nome: 'Next.js', level: 75 },
      { nome: 'Vue', level: 90 },
      { nome: 'Nuxt.js', level: 80 },
      { nome: 'Angular', level:90 },
      { nome: 'Laravel', level: 70 },
      { nome: 'Ionic', level: 50 },
      { nome: 'Flutter', level: 50 },
    ],
    tools: [
      { nome: 'git', level: 100 },
      { nome: 'tailwind', level: 100 },
    ],
    libs: [
      { nome: 'axios', level: 100 },
      { nome: 'redux', level: 100 },
      { nome: 'rxjs', level: 100 },
      { nome: 'vuex', level: 100 },
    ],
    languagesENG: [
      { nome: 'Portuguese', level: 100 },
      { nome: 'English', level: 100 },
      { nome: 'Japanese', level: 80 },
      { nome: 'Spanish', level: 60 },
    ],
    languagesPTBR: [
      { nome: 'Inglês', level: 100 },
      { nome: 'Japonês', level: 80 },
      { nome: 'Espanhol', level: 60 },
    ]
  }
  
  const infoJhey = {
      aboutMe: {
        eng: "Hello, I'm Jhey! I'm a gaming dev enthusiast and a front-end development specialist, always staying updated on the latest trends in both worlds. I'm on the lookout for exciting projects where I can apply my creativity and expertise.",
        ptbr: "Olá, eu sou Jhey! Sou uma pessoa entusiasta na criação de games, além de ser especialista em desenvolvimento front-end e sempre ligada nas últimas novidades em ambos os universos, à procura de projetos empolgantes onde eu possa colocar minha criatividade e conhecimento em prática."
      },
      interests: {
        row1: {
          eng: "purple things, games, animes,",
          ptbr: "coisas roxas, games, animes,"
        },
        row2: {
          eng: "mangas, series, movies,",
          ptbr: "mangás, seriados, filmes,"
        },
        row3: {
          eng: "sushi and my wife",
          ptbr: "sushi e minha esposa"
        }
      },
      details: {
        row1: {
          eng: 'Freelancer / Remote',
          ptbr: 'PJ / CLT / Freelancer / Trabalho Remoto'
        },
        row2: {
          eng: 'Brazil - São Paulo - SP (East Zone)',
          ptbr: 'Brasil - São Paulo - SP (Zona Leste)'
        },
        row3: {
          eng: 'jhey.murasaki@gmail.com',
          ptbr: 'jhey.murasaki@gmail.com'
        },
        row4: {
          eng: '+55 (11) 9-5046-4158',
          ptbr: '+55 (11) 9-5046-4158'
        }
      },
      education: {
        row1: {
          eng: 'Primary School - Ateneu Monte Virgem',
          ptbr: 'Fundamental - Ateneu Monte Virgem'
        },
        row2: {
          eng: 'Technical High School in IT - Colégio Futuro',
          ptbr: 'Colegial Técnico em TI - Colégio Futuro'
        },
        row3: {
          eng: 'Adobe Collection - Saga',
          ptbr: 'Adobe Collection - Saga'
        },
        row4: {
          eng: 'Game Development ( UDK3 / MAYA - 3D Modeling ) - Saga',
          ptbr: 'Desenvolvimento de Games ( UDK3 / MAYA - Modelagem 3D ) - Saga'
        },
        row5: {
          eng: 'React - Academind',
          ptbr: 'React - Academind'
        },
        row6: {
          eng: 'React Native - Academind',
          ptbr: 'React Native - Academind'
        },
        row7: {
          eng: 'Next.js - Academind',
          ptbr: 'Next.js - Academind'
        },
        row8: {
          eng: 'Vue.js - Academind',
          ptbr: 'Vue.js - Academind'
        }
      },
      i_know: { 
        eng: "(Yes, I know, it's a lib.)",
        ptbr: "(Eu sei, é uma lib.)"
    }
  }


  useEffect(() => {
    console.log("get data");
  }, []);


  return (
    <div className="App" ref={componentRef}>

      <div className="resume-content">

        <div className="resume-grid">

          <div className="col-left">

            <ChangeLanguage idioma={language} setIdioma={setLanguage} />

            <div className='download-cv' onClick={handlePrint}>
              <FaFileDownload />
            </div>

            <div className="avatar">
              <img src="./imgs/jhey.jpg"
              alt="It's a me, Jhey."
              title="It's a me, Jhey."
              ></img>
            </div>

            {/* <div className="avatar">
              <img src="./imgs/jhey.jpg"
              alt="Avatar - Ashley from VoidOfSpace made by: rhadog"
              title="Avatar - Ashley from VoidOfSpace made by: rhadog"
              ></img>
            </div> */}

            <div className="name-box">
              <div className="nick">Jhey</div>
              <div className="job">front-End</div>
              <div className="job">game dev / web dev</div>
            </div>

            <div className="bio-title">
              { language === 'eng' ? <> <FaFileAlt size={20} /> {menuItems.aboutMe.eng} </> : null } 
              { language === 'ptbr' ? <> <FaFileAlt size={20} /> {menuItems.aboutMe.ptbr} </> : null }
				    </div>

            <div className="mini-bio">
              { language === 'eng' ? <> {infoJhey.aboutMe.eng} </> : null }
              { language === 'ptbr' ? <> {infoJhey.aboutMe.ptbr} </> : null }
				    </div>

            <div className="bio-title">
              { language === 'eng' ? <> <FaGamepad size={25} /> {menuItems.interests.eng} </> : null } 
              { language === 'ptbr' ? <> <FaGamepad size={25} /> {menuItems.interests.ptbr} </> : null }

            </div>

            <div className="info-list">
              { language === 'eng' ? <> 
                <div> {infoJhey.interests.row1.eng} </div>
                <div> {infoJhey.interests.row2.eng} </div>
                <div> {infoJhey.interests.row3.eng} <FaHeart /> </div>
              </> : null } 

              { language === 'ptbr' ? <> 
                <div> {infoJhey.interests.row1.ptbr} </div>
                <div> {infoJhey.interests.row2.ptbr} </div>
                <div> {infoJhey.interests.row3.ptbr} <FaHeart /> </div>
              </> : null } 
            </div>

            <div className="bio-title">
              { language === 'eng' ? <> <FaHouseUser size={22} /> {menuItems.details.eng} </> : null } 
              { language === 'ptbr' ? <> <FaHouseUser size={22} /> {menuItems.details.ptbr} </> : null }
            </div>

            <div className="info-list">
              { language === 'eng' ? <> 
                <div> <FaBriefcase /> {infoJhey.details.row1.eng} </div>
                <div> <FaMapMarkedAlt /> {infoJhey.details.row2.eng} </div>
                <div> <FaEnvelope /> {infoJhey.details.row3.eng} </div>
                <div> <FaWhatsapp /> {infoJhey.details.row4.eng} </div>
              </> : null } 
              { language === 'ptbr' ? <> 
                <div> <FaBriefcase /> {infoJhey.details.row1.ptbr} </div>
                <div> <FaMapMarkedAlt /> {infoJhey.details.row2.ptbr} </div>
                <div> <FaEnvelope /> {infoJhey.details.row3.ptbr} </div>
                <div> <FaWhatsapp /> {infoJhey.details.row4.ptbr} </div>
              </> : null } 
            </div>

            <div className="bio-title">
              { language === 'eng' ? <> <FaGraduationCap size={25} /> {menuItems.education.eng} </> : null } 
              { language === 'ptbr' ? <> <FaGraduationCap size={25} /> {menuItems.education.ptbr} </> : null }
            </div>

            <div className="info-list">
              { language === 'eng' ? <> 
                <div> {infoJhey.education.row1.eng} </div>
                <div> {infoJhey.education.row2.eng} </div>
                {/* <div> {infoJhey.education.row3.eng} </div> */}
                <div> {infoJhey.education.row4.eng} </div>
                {/* <br></br>
                <div> {infoJhey.education.row5.eng} </div>
                <div> {infoJhey.education.row6.eng} </div>
                <div> {infoJhey.education.row7.eng} </div>
                <div> {infoJhey.education.row8.eng} </div> */}
              </> : null } 
              { language === 'ptbr' ? <> 
                <div> {infoJhey.education.row1.ptbr} </div>
                <div> {infoJhey.education.row2.ptbr} </div>
                {/* <div> {infoJhey.education.row3.ptbr} </div> */}
                <div> {infoJhey.education.row4.ptbr} </div>
                {/* <br></br>
                <div> {infoJhey.education.row5.ptbr} </div>
                <div> {infoJhey.education.row6.ptbr} </div>
                <div> {infoJhey.education.row7.ptbr} </div>
                <div> {infoJhey.education.row8.ptbr} </div> */}
              </> : null } 
            </div>

          </div>

          <div className="col-right">
            {/* engines */}
            <div className="item-title">
              { language === 'eng' ? <> <FaRobot /> {menuItems.engines.eng} </> : null } 
              { language === 'ptbr' ? <> <FaRobot /> {menuItems.engines.ptbr} </> : null }
            </div>
            <Lista dados={ArrayJhey.engines} />

            {/* coding */}
            <div className="item-title">
              { language === 'eng' ? <> <FaCode /> {menuItems.coding.eng} </> : null } 
              { language === 'ptbr' ? <> <FaCode /> {menuItems.coding.ptbr} </> : null }
            </div>
            <Lista dados={ArrayJhey.coding} />

            {/* frameworkds */}
            <div className="item-title">
              { language === 'eng' ? <> <FaTerminal /> {menuItems.frameworks.eng} </> : null } 
              { language === 'ptbr' ? <> <FaTerminal /> {menuItems.frameworks.ptbr} </> : null }
            </div>
            <Lista dados={ArrayJhey.frameworks} />

            {/* tools */}
            <div className="item-title">
              { language === 'eng' ? <> <FaWrench />  {menuItems.tools.eng} </> : null } 
              { language === 'ptbr' ? <> <FaWrench />  {menuItems.tools.ptbr} </> : null }
            </div>
            <Lista dados={ArrayJhey.tools} />

            {/* libs */}
            <div className="item-title">
              { language === 'eng' ? <> <FaBook />  {menuItems.libs.eng} </> : null } 
              { language === 'ptbr' ? <> <FaBook />  {menuItems.libs.ptbr} </> : null }
            </div>
            <Lista dados={ArrayJhey.libs} />

            {/* languages */}
            <div className="item-title">
              { language === 'eng' ? <> <FaGlobe />  {menuItems.languages.eng} </> : null } 
              { language === 'ptbr' ? <> <FaGlobe />  {menuItems.languages.ptbr} </> : null }
            </div>
            { language === 'eng' ? <> <Lista dados={ArrayJhey.languagesENG} /> </> : null } 
            { language === 'ptbr' ? <> <Lista dados={ArrayJhey.languagesPTBR} /> </> : null } 

          </div>
        </div>

      </div>




    </div>
  );
}

export default Home;
