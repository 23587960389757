import React, { useState, useEffect } from 'react';
import styles from '../trophyApp.module.scss';

import BackBtnComponent from './btnBack';
import AvatarComp from './avatar';

const EditProfileComp = ({ backPage, showHover, disableHover, menuStates, setLoad, setProfileName, setProfileUrl, profileName, profileUrl }) => {

  const [name, setName] = useState(profileName);
  const [avatarUrl, setAvatarUrl] = useState(profileUrl);
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);

  function validarURL(url) {
    try {
      new URL(url);
      return true;
    } catch (erro) {
      return false;
    }
  }

  const save = () => {
    setLoad(true);
    setError(false);
    setError2(false);
    setTimeout(() => {
        if(name.trim() === '' || avatarUrl.trim() === '') {
            setError(true);
        } else {
            if (validarURL(avatarUrl)) {
                setError(false);
                backPage('home')

                setProfileName(name.trim());
                setProfileUrl(avatarUrl.trim());

                setTimeout(() => {
                  setLoad(false);
                }, 250);
            } else {
                setError(true);
                setError2(true);
                setLoad(false);
            }
        }
    }, 250);
  };

  useEffect(() => {
    setError(false);
    setError2(false);
  }, []);


  return (
    <div className={styles['app-box']} style={{paddingTop: '75px'}}>

        <BackBtnComponent
            pageName="home"
            backPage={backPage}
            showHover={showHover}
            disableHover={disableHover}
            menuStates={menuStates}
        />

        <div className={styles['edit-profile-title']}>Edit Profile</div>

        { validarURL(avatarUrl) ? 
                <AvatarComp profileUrl={avatarUrl} />
        : null}

        <div> Name </div>
        <input type="text" placeholder={name} className={styles['edit-profile-input']} onChange={(e) => setName(e.target.value)} />
       
        <div> Avatar </div>
        <input type="text" placeholder={avatarUrl} className={styles['edit-profile-input']} onChange={(e) => setAvatarUrl(e.target.value)} />

        <div className={styles['salvar-btn']} onClick={()=> save()}> Salvar </div>

        { error ? <div className={styles['error']}> 
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="currentColor" d="M12 17q.425 0 .713-.288T13 16q0-.425-.288-.712T12 15q-.425 0-.712.288T11 16q0 .425.288.713T12 17m-1-4h2V7h-2zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"/></svg> 
        Name and Avatar cannot be empty </div> : null }

        { error2 ? <div className={styles['error']}> 
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="currentColor" d="M12 17q.425 0 .713-.288T13 16q0-.425-.288-.712T12 15q-.425 0-.712.288T11 16q0 .425.288.713T12 17m-1-4h2V7h-2zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"/></svg> 
       Invalid URL </div> : null }

    </div>
  );
};

export default EditProfileComp;
