import React, { useState } from 'react';
import styles from '../trophyApp.module.scss';
import HoverInfoComp from './hoverInfo';

const IcoTrophyCheckBox = ({ index, trophyEarned, checkTrophy}) => {

  const [menuState, setMenuState] = useState(false);

  return (
    <div className={styles['trophy-earned']} style={{flex: '1'}} onMouseEnter={()=> setMenuState(true)} onMouseLeave={()=> setMenuState(false)}>
    {trophyEarned 
        ? 
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" onClick={()=> checkTrophy(index)}><path fill="#fff" d="m10.6 16.2l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4zM5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v14q0 .825-.587 1.413T19 21z"/></svg> 
        : 
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"onClick={()=> checkTrophy(index)}><path fill="#fff" d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v14q0 .825-.587 1.413T19 21zm0-2h14V5H5z"/></svg>
        }
    {/* { menuState ? <HoverInfoComp text={"Set trophy on / off"} position={"center"} /> : null } */}
    </div>
  );
};

export default IcoTrophyCheckBox;

