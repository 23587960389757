
export const gameModel = [
    {
        'name': 'Game Name 1',
        'platform': 'PS5',
        'image': 'https://upload.wikimedia.org/wikipedia/en/6/65/Persona_3_Reload_box_art.jpg',
        'trophyList': [
            {
                'name': 'My first trophy',
                'description': 'This is my first trophy',
                'type': 'Platinum',
                'earned': true,
            },
            {
                'name': 'My second trophy',
                'description': 'This is my second trophy',
                'type': 'Gold',
                'earned': true,
            },
            {
                'name': 'My third trophy',
                'description': 'This is my third trophy',
                'type': 'Silver',
                'earned': true,
            },
            {
                'name': 'My fourth trophy',
                'description': 'This is my fourth trophy',
                'type': 'Bronze',
                'earned': true,
            },
        ],
    },
    {
        'name': 'Game Name 2',
        'platform': 'PS5',
        'image': 'https://upload.wikimedia.org/wikipedia/pt/c/cf/Like_a_Dragon_Infinite_Wealth_PlayStation_5_capa_digital.png',
        'trophyList': [
            {
                'name': 'My first trophy',
                'description': 'This is my first trophy',
                'type': 'Platinum',
                'earned': true,
            },
            {
                'name': 'My second trophy',
                'description': 'This is my second trophy',
                'type': 'Gold',
                'earned': true,
            },
            {
                'name': 'My third trophy',
                'description': 'This is my third trophy',
                'type': 'Silver',
                'earned': true,
            },
            {
                'name': 'My fourth trophy',
                'description': 'This is my fourth trophy',
                'type': 'Bronze',
                'earned': true,
            },
        ],
    },
]


export const emptyGame = {
    'name': 'Game Name',
    'platform': 'Console',
    'image': '/imgs/trophyApp/amongus-cover.jpg',
    'trophyList': [
        {
            'name': 'My first trophy',
            'description': 'This is my first trophy',
            'type': 'Platinum',
            'earned': true,
        },
        {
            'name': 'My second trophy',
            'description': 'This is my second trophy',
            'type': 'Gold',
            'earned': true,
        },
        {
            'name': 'My third trophy',
            'description': 'This is my third trophy',
            'type': 'Silver',
            'earned': true,
        },
        {
            'name': 'My fourth trophy',
            'description': 'This is my fourth trophy',
            'type': 'Bronze',
            'earned': true,
        },
    ],
}

export const emptyTrophy = {
    'name': 'My first trophy',
    'description': 'This is my first trophy',
    'type': 'Bronze',
    'earned': true,
}