import React from 'react';
import HoverInfoComp from './hoverInfo';

const BtnAddNewGameComp = ({ addEmptyGame, showHover, disableHover, menuStates}) => {
  return (
    <div style={{position: 'relative'}} onClick={() => addEmptyGame()} onMouseEnter={()=> showHover('add-game-menu')} 
    onMouseLeave={()=> disableHover('add-game-menu')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M11 17h2v-4h4v-2h-4V7h-2v4H7v2h4zm-6 4q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v14q0 .825-.587 1.413T19 21z"/></svg>
        { menuStates["add-game-menu"] ? <HoverInfoComp text={"Add new game"} position={"center"} /> : null }
    </div>
  );
};

export default BtnAddNewGameComp;
