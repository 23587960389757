import React from 'react';
import styles from '../trophyApp.module.scss';

const TrophyInfoComp = ({ trophyName, trophyDescription}) => {
  return (
    <div className='trophy-info' style={{flex: '4'}}>
        <div className={styles['trophy-name']}>
            {trophyName}
        </div>
        <div className={styles['trophy-desc']}>
            {trophyDescription}
        </div>
    </div>
  );
};

export default TrophyInfoComp;
