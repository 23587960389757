import React, { useState, useEffect } from 'react';
import styles from './trophyApp.module.scss';
import '@fontsource-variable/nunito-sans/full.css';

// data
import { gameModel, emptyGame, emptyTrophy} from './gameList';

// template
import EditProfileComp from './components/editProfile';
import BtnBackComp from './components/btnBack';
import BtnSaveComp from './components/btnSave';
import AvatarComp from './components/avatar';
import BtnEditProfileComp from './components/btnEditProfile';
import BtnSettingsComp from './components/btnSettings';
import BtnAddNewGameComp from './components/btnAddNewGame';

import GameImgComp from './components/gameImg';
import GameNameComp from './components/gameName';
import TrophyCountComp from './components/trophyCount';
import ConsoleTagComp from './components/ConsoleTag';
import BtnEditGameComp from './components/btnEditGame';

import BtnAddNewTrophyComp from './components/btnAddNewTrophy';
import IcoTrophyColor from './components/icoTrophyColor';
import IcoTrophyCheckBox from './components/icoTrophyCheckBox';
import TrophyInfoComp from './components/trophyInfo';
import TrophyImgComp from './components/trophyImg';
import BtnEditTrophyComp from './components/btnEditTrophy';


// main component
function TrophyApp() {

const [page, setPage] = useState('home');
const [load, setLoad] = useState(true);
const [games, setGames] = useState([]);
const [actualGame, SetActualGame]= useState({});
const [actualGameIndex, SetActualGameIndex]= useState(-1);
const [profileName, setProfileName] = useState('Jhey Murasaki');
const [profileUrl, setProfileUrl] = useState('https://jheymurasaki.com/avatar-lucy.png');

const [menuStates, setMenuStates] = useState({
    'add-game-menu': false,
    'add-trophy-menu': false,
    'edit-profile-menu': false,
    'settings-menu': false,
    'save-menu': false,
    'edit-game-menu': false,
    'edit-trophy-menu': false,
  });

const addEmptyGame = () => {
    let newItems = [...games];
    let newGame = JSON.parse(JSON.stringify(emptyGame));
    newItems.push(newGame);
    setGames(newItems);
}

const addEmptyTrophy = () => {
    // add trophy to game
    let updatedActualGame = { ...actualGame };
    let newTrophy = JSON.parse(JSON.stringify(emptyTrophy));
    updatedActualGame.trophyList = [...updatedActualGame.trophyList, newTrophy];
    SetActualGame(updatedActualGame);
    // update game list
    let updatedGames = [...games];
    updatedGames[actualGameIndex] = updatedActualGame;
    setGames(updatedGames);
}

const openGame = (id) => {
    setLoad(true);
    const oppenedGame = games[id];
    SetActualGame(oppenedGame);
    SetActualGameIndex(id);
    console.log(oppenedGame);
    console.log(id);

    setPage("inside");

    setTimeout(() => {
        setLoad(false);
    }, 250);

    resetMenus();
}

const backPage = (pageName) => {
    setLoad(true);
    setPage(pageName);
    setTimeout(() => {
        setLoad(false);
    }, 250);
    resetMenus();
}

const resetMenus = () => {
setMenuStates(prevState => {
    const newState = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false; // Define cada item como false
        return acc;
    }, {});
    return newState;
    });
}

const showHover = (id) => {
    //document.getElementById(id).style.display = 'block';
    setMenuStates({...menuStates, [id]: true});
}

const disableHover = (id) => {
    //document.getElementById(id).style.display = 'none';
    setMenuStates({...menuStates, [id]: false});
}
  
const checkTrophy = (index) => {

    console.log(actualGame.trophyList[index]);

    // atualizar game atual
    let gameEdit = { ...actualGame };
    gameEdit.trophyList = [...gameEdit.trophyList];
    gameEdit.trophyList[index].earned = !gameEdit.trophyList[index].earned;
    SetActualGame(gameEdit);

    // atualizar lista total
    let gamesCopy = [...games];
    gamesCopy[actualGameIndex] = gameEdit;
    setGames(gamesCopy);

}

const goToProfile = () => {
    console.log('clicou')
    setLoad(true);
    setPage('edit-profile');
    setTimeout(() => {
        setLoad(false);
    }, 250);
}

useEffect(() => {
    //change bg color
    document.body.style.backgroundColor = "#1e1e1e";

    // load games
    //const localData = localStorage.getItem('trophyAppData');
    //setGames(localData ? JSON.parse(localData) : gameModel);
    
    //load data
    setGames(gameModel);
    setTimeout(() => {
        setLoad(false);
    }, 250);

}, []);

  return (
<>
        { !load 
        ? 
        <>
        {/** not loading */}
        
            { page === 'edit-profile' ? 
                <EditProfileComp
                    backPage={backPage}
                    showHover={showHover}
                    disableHover={disableHover}
                    menuStates={menuStates}
                    setLoad={setLoad}
                    setProfileName={setProfileName}
                    setProfileUrl={setProfileUrl}
                    profileName={profileName}
                    profileUrl={profileUrl}
                /> 
            : null }
        
            { page === 'home' ?
                <>
                    <div className={styles['app-box']}>
                        <BtnSaveComp
                            showHover={showHover}
                            disableHover={disableHover}
                            menuStates={menuStates}
                        />

                        <AvatarComp 
                            profileUrl={profileUrl} 
                        />

                        <div className={styles['username']}> 
                            {profileName} 
                        </div>

                        <div className={styles['configs']}>
                            <BtnEditProfileComp goToProfile={goToProfile} />
                            <BtnSettingsComp />
                        </div>

                        <div className={styles['line']}></div>

                        <div className={styles['games']}>
                            <div> My Games </div>
                            <BtnAddNewGameComp 
                                addEmptyGame={addEmptyGame} 
                                showHover={showHover} 
                                disableHover={disableHover} 
                                menuStates={menuStates} 
                            />
                        </div>
            
                        <div className={styles['game-list']}>
                                {games.map((game, index) => (
                                    <div key={index} className={styles['game-item']} onClick={() => openGame(index)} style={{ backgroundColor: index % 2 === 0 ? '#353942' : '#32353D' }}>
                                        
                                        <GameImgComp src={game.image} alt={game.name} />

                                        <GameNameComp gameName={game.name} />

                                        <TrophyCountComp trophyList={game.trophyList} />

                                        <ConsoleTagComp plataform={game.platform} />

                                        <BtnEditGameComp />

                                    </div>
                                ))}
                        </div>
                    </div>
                </>
            : null }

            { page === 'inside' ?
            <>
                <div className={styles['app-box']} style={{paddingTop: '75px'}}>

                    <BtnBackComp
                        pageName="home"
                        backPage={backPage}
                        showHover={showHover}
                        disableHover={disableHover}
                        menuStates={menuStates}
                    />

                    <div className={styles['games']}>
                        <div> {actualGame.name} </div>
                        <BtnAddNewTrophyComp
                            addEmptyTrophy={addEmptyTrophy}
                            showHover={showHover}
                            disableHover={disableHover}
                            menuStates={menuStates}
                        />
                    </div>

                    <div className={styles['trophy-list']}>
                        {actualGame.trophyList.map((trophy, index) => (
                            <div key={index} className={styles['trophy-item']} style={{ backgroundColor: index % 2 === 0 ? '#353942' : '#32353D' }}>

                                <TrophyImgComp src={actualGame.image} alt={trophy.name} />

                                <TrophyInfoComp trophyName={trophy.name} trophyDescription={trophy.description} />

                                <IcoTrophyColor trophyType={trophy.type} />

                                <IcoTrophyCheckBox index={index} trophyEarned={trophy.earned} checkTrophy={checkTrophy} />

                                <BtnEditTrophyComp />

                            </div>
                        ))}
                    </div>
                </div>
            </>
            : null}


        {/** not loading */}
        </>
        : 
        <div className={styles['spinner-box']}>
            <div className={styles['spinner']}></div>
        </div> 
        }
</>
  );
}

export default TrophyApp;
