
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './views/home/home';
import TrophyApp from './views/trophyApp/trophyApp';
import Resume from './views/resume/resume';


function App() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/trophyapp" element={<TrophyApp />} />
          <Route path="/resume" element={ <Resume /> } />
        </Routes>
      </Router>
    );
  }
  
  export default App;

  